<template>
  <gc-show-domain-slide
    class="global-components"
    :name-tag-title="'Your Domain'"
    :domain-name="domainName"
    :slide-description="slideDescription"
    @update:copy="handleCopy($event)"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'BusinessDomainSlide',

  mixins: [
    copyClipboardMixin,
    makeToastMixin,
  ],

  props: {
    slide: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),

    domainName() {
      return this.domain?.domain_name
    },

    slideDescription() {
      return JSON.parse(this.slide)?.description || ''
    },
  },

  methods: {
    handleCopy(event) {
      const textToCopy = event?.detail?.[0]
      this.copyToClipboard(textToCopy, null, this.successToast, this.errorToast)
    },
  },
}
</script>
